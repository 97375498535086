import React from 'react';
import styled from 'styled-components';

const AppHeader = styled.header`
  background-color: #282c34;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  cursor: pointer;

  h2 {
    margin: 0;
  }
`

interface HeaderProps {
  text: string;
}

const Header: React.FC<HeaderProps> = ({text}) => {
  return (
    <AppHeader>
      <h2>{text}</h2>
    </AppHeader>
  )
}

export default Header;
