import React from 'react';
import styled from 'styled-components';
import { MovieJSON } from '../interfaces/SearchResults';

const DEFAULT_PLACEHOLDER_IMAGE = "https://m.media-amazon.com/images/M/MV5BMTczNTI2ODUwOF5BMl5BanBnXkFtZTcwMTU0NTIzMw@@._V1_SX300.jpg";

const MovieWrapper = styled.div`
  padding: 5px 25px 10px 25px;
  max-width: 25%;
  @media screen and (min-width: 694px) and (max-widht: 915px) {
    max-width: 33%;
  }
  @media screen and (min-width: 652px) and (max-width: 693px) {
    max-width: 50%;
  }
  @media screen and (max-width: 651px) {
    max-width: 100%;
    margin: auto;
  }
`

interface MovieProps {
  movie: MovieJSON
}

const Movie: React.FC<MovieProps> = ({ movie }) => {
  const poster = movie.Poster === "N/A" ? DEFAULT_PLACEHOLDER_IMAGE : movie.Poster;
  return (
    <MovieWrapper>
      <h2>{movie.Title}</h2>
      <div>
        <img width="200" alt={`The movie titled: ${movie.Title}`} src={poster} />
      </div>
      <p>({movie.Year})</p>
    </MovieWrapper>
  )
}

export default Movie;
