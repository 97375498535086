import React, { useState } from 'react';
import styled from 'styled-components';

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;

  input[type="submit"] {
    padding: 5px;
    background-color: transparent;
    color: black;
    border: 1px solid black;
    width: 80px;
    margin-left: 5px;
    cursor: pointer;
  }

  input[type="submit"]:hover {
    background-color: #282c34;
    color: antiquewhite;
  }

  input[type="text"] {
    width: 40%;
    min-width: 170px;
  }
`;


const Search = (props: { search: (arg0: string) => void; }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChanges = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchValue(e.target.value);
  }

  const resetInputField = () => {
    setSearchValue("")
  }

  const callSearchFunction = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.search(searchValue);
    resetInputField();
  }

  return (
    <SearchForm>
      <input value={searchValue} onChange={handleSearchInputChanges} type="text" />
      <input onClick={callSearchFunction} type="submit" value="SEARCH" />
    </SearchForm>
  )
}

export default Search;
