import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Header from './Header';
import Movie from './Movie';
import Search from './Search';
import { MovieJSON } from '../interfaces/SearchResults';

const MOVIE_API_URL = `https://www.omdbapi.com/?s=man&apikey=${process.env.REACT_APP_API_KEY}`;

const AppWrapper = styled.div`
  text-align: center;
  p {
    font-size: large;
  }
`;

const Movies = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ErrorMessage = styled.div`
  margin: auto;
  font-weight: bold;
  color: rgb(161,15,15);
`

const App = () => {
  const [loading, setLoading] = useState(true);
  const [movies, setMovies] = useState<MovieJSON[]>([]);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetch(MOVIE_API_URL)
      .then(response => response.json())
      .then(jsonResponse => {
        setMovies(jsonResponse.Search);
        setLoading(false);
      })
  }, []);

  const search = (searchValue: string) => {
    setLoading(true);
    setErrorMessage(null);
    fetch(`https://www.omdbapi.com/?s=${searchValue}&apikey=${process.env.REACT_APP_API_KEY}`)
      .then(response => response.json())
      .then(jsonResponse => {
        if (jsonResponse.Response === 'True') {
          setMovies(jsonResponse.Search);
          setLoading(false);
        } else {
          setErrorMessage(jsonResponse.Error);
          setLoading(false)
        }
      })
  }

  return (
    <AppWrapper>
      <Header text="MOVIE SEARCH" />
      <Search search={search} />
      <p>Sharing a few of our favourite movies</p>
      <Movies>
        {loading && !errorMessage ?
          (<span>loading...</span>) : errorMessage ?
            (<ErrorMessage>{errorMessage}</ErrorMessage>) :
            (movies.map((movie, index) => (<Movie key={`${index}-${movie.Title}`} movie={movie} />)))
        }
      </Movies>
    </AppWrapper>
  )
}

export default App;
